import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SousThematiqueInterface } from '@/interfaces/sousthematiques';
import { BehaviorSubject, catchError, forkJoin, from, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SousthematiquesService {
    constructor(public apiService: ApiService) {}

    public sousthematiques: BehaviorSubject<SousThematiqueInterface> =
        new BehaviorSubject<SousThematiqueInterface>({} as SousThematiqueInterface);

    loadSousThematiques(): Promise<{error: boolean; msg?: string}> {
        return new Promise((resolve, reject) => {
            forkJoin([from(this.apiService.getRequest('/api/sousthematiques'))])
                .pipe(catchError((err) => of({error: true, msg: err})))
                .subscribe((results) => {
                    this.sousthematiques.next(results[0]);

                    resolve({
                        msg: 'Success',
                        error: false
                    });
                });
        });
    }

    addSousThematique(data: FormData): Promise<{error: boolean; msg?: string}> {
        return new Promise((resolve, reject) => {
            forkJoin([
                from(
                    this.apiService.post_requete_file('/api/sousthematiques', data)
                )
            ])
                .pipe(catchError((err) => of({error: true, msg: err})))
                .subscribe((results) => {
                    resolve({
                        msg: 'Success',
                        error: false
                    });
                });
        });
    }

    updateSousThematique(
        id: number,
        data: FormData
    ): Promise<{error: boolean; msg?: string}> {
        return new Promise((resolve, reject) => {
            forkJoin([
                from(
                    this.apiService.post_requete_file(
                        '/api/sousthematiques/' + id,
                        data
                    )
                )
            ])
                .pipe(catchError((err) => of({error: true, msg: err})))
                .subscribe((results) => {
                    resolve({
                        msg: 'Success',
                        error: false
                    });
                });
        });
    }

    deleteSousThematique(id: number): Promise<{error: boolean; msg?: string}> {
        return new Promise((resolve, reject) => {
            forkJoin([
                from(
                    this.apiService.post_requete('/api/sousthematiques/' + id, {
                        _method: 'delete'
                    })
                )
            ])
                .pipe(catchError((err) => of({error: true, msg: err})))
                .subscribe((results) => {
                    resolve({
                        msg: 'Success',
                        error: false
                    });
                });
        });
    }

    getSousThematiques(): SousThematiqueInterface {
        return this.sousthematiques.getValue();
    }
}
