<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Editer la Thématique</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                    <li class="breadcrumb-item active">Editer Thématique</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
   <div class="container">

    
    <div class="row ">
      <div class="col-lg-7 mx-auto">
        <div class="card mt-2 mx-auto p-4 bg-light">
            <div class="card-body bg-light">
       
            <div class = "container">
                             <form id="contact-form" role="form" [formGroup]="thematiqueUpdateForm" (ngSubmit)="updateThematique()" >

            <div class="controls">

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_name">Nom *</label>
                            <input  formControlName="nom" id="form_nom" type="text" name="nom" class="form-control" placeholder="Entrez le nom de la thématique *" required="required" data-error="Le nom est requis">
                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Nom Anglais *</label>
                            <input  formControlName="nom_en" id="form_nom_en" type="text" name="nom_en" class="form-control" placeholder="Entrez le nom de la thématique en anglais *" required="required" data-error="Le nom anglais est requis">
                                                            </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_color">Couleur *</label>
                            <input  [value]="thematique.color" id="form_color" name="color"
       [style.background]="thematique.color"
       [cpAlphaChannel]="'always'"
       [cpOutputFormat]="'hex'"
       [(colorPicker)]="thematique.color" (colorPickerChange)="updateColor($event)"/>
                            
                        </div>
                    </div>
                  
                </div>
                <div class="row">
                    <div class="col-8">
    <label class="btn btn-default p-0">
        <label for="form_image">Choisir un logo *</label>
      <input type="file" accept="image/*" (change)="selectFile($event)" />
    </label>
  </div>

          
                </div>

                <div>
  <img height="200" width="200" alt="Logo" [src]="preview" class="preview">
</div>

  <div class="col-md-12">
                        
                       <pf-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isThematiqueUpdateLoading"
                    >
                        Mettre à jour la thématique
                    </pf-button>
                    
                </div>


        </div>
         </form>
        </div>
            </div>


    </div>
        <!-- /.8 -->

    </div>
    <!-- /.row-->

</div>
</div>

</section>
<!-- /.content -->
