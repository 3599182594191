import { SousThematique } from '@/interfaces/sousthematiques';
import { Thematique } from '@/interfaces/thematiques';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SousthematiquesService } from '@services/sousthematiques.service';
import { ThematiquesService } from '@services/thematiques.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-edit.sousthematique',
    templateUrl: './edit.sousthematique.component.html',
    styleUrls: ['./edit.sousthematique.component.scss']
})
export class EditSousthematiqueComponent implements OnInit {
  sousthematique: SousThematique;
  thematiques: Thematique[];
    environment;
    public issousThematiqueUpdateLoading = false;
    public sousthematiqueUpdateForm: UntypedFormGroup;

    selectedFiles?: FileList;
    currentFile?: File;
    progress = 0;
    message = '';
    preview = '';

    data: {sousthematique: SousThematique; site: string} = {
        sousthematique: undefined,
        site: ''
    };
    routeState: any;

    constructor(
        public router: Router,
        public sousthematiquesService: SousthematiquesService,
        public thematiquesService: ThematiquesService,
        private toastr: ToastrService
    ) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            this.routeState = this.router.getCurrentNavigation()?.extras.state;
            if (this.routeState) {
                this.data.sousthematique = this.routeState.sousthematique
                    ? JSON.parse(this.routeState.sousthematique)
                    : '';
                this.sousthematique = this.data.sousthematique;
                this.data.site = this.routeState.site
                    ? this.routeState.site
                    : '';
            }
        }
    }

  ngOnInit(): void {
      
      this.thematiques =
          this.thematiquesService.getThematiques().data.thematiques;
        this.environment = environment;
        this.preview = environment.apiUrl + this.sousthematique.image_src;
        this.sousthematiqueUpdateForm = new UntypedFormGroup({
            nom: new UntypedFormControl(
                this.sousthematique.nom,
                Validators.required
            ),
            nom_en: new UntypedFormControl(
                this.sousthematique.nom_en,
                Validators.required
            ),
            thematiques: new UntypedFormControl(
                this.sousthematique.thematique[0].id,
                Validators.required
            )
        });
    }

    selectFile(event: any): void {
        this.message = '';
        this.preview = '';
        this.progress = 0;
        this.selectedFiles = event.target.files;

        if (this.selectedFiles) {
            const file: File | null = this.selectedFiles.item(0);

            if (file) {
                this.preview = '';
                this.currentFile = file;

                const reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e.target.result);
                    this.preview = e.target.result;
                };

                reader.readAsDataURL(this.currentFile);
            }
        }
    }

    async updateSousThematique() {
        let file: File | null = null;
        const formData = new FormData();
        if (this.selectedFiles) {
            file = this.selectedFiles.item(0);
            formData.append('image_src', file, file.name);
        }

        if (this.sousthematiqueUpdateForm.valid) {
            this.issousThematiqueUpdateLoading = true;

            formData.append('nom', this.sousthematiqueUpdateForm.value.nom);
            formData.append(
                'nom_en',
                this.sousthematiqueUpdateForm.value.nom_en
            );
          formData.append(
              'thematique_id',
              this.sousthematiqueUpdateForm.value.thematiques
          );

            formData.append('_method', 'put');

            await this.sousthematiquesService
                .updateSousThematique(this.sousthematique.id, formData)
                .then((response: {error: boolean; msg?: string}) => {
                    if (response.error) {
                        this.toastr.error('Echec de Mise à Jour');
                    } else {
                        this.toastr.success(
                            'Mise à jour de la sous-thématique réussie'
                        );
                        this.router.navigate(['/sousthematiques']);
                    }
                });
            this.issousThematiqueUpdateLoading = false;
        } else {
            this.toastr.error('Formulaire non valide');
        }
    }
}
